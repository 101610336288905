import { createApp } from 'vue'
import App from '@us/app/App.vue'
import initRouter from '@us/app/router'
import store from '@us/app/state/store'
import i18n from '@shared/localization'
import { initApi } from '@shared/api-client'
import directives from '@shared/directives'
import Tracker from '@shared/Tracker'
import getCorporateTrackingSearchParams from '@us/app/helpers/getCorporateTrackingSearchParams'
import { generateSignupContext } from '@shared/utils'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// replace corporate and query from tracking map before router, tracker, and signupContext are initialized
const nextQueryParams = getCorporateTrackingSearchParams(
  window.location.search,
  'corporate',
)
if (nextQueryParams != null) {
  const querystring = new URLSearchParams(nextQueryParams).toString()
  const url = `${window.location.pathname}?${querystring}${window.location.hash}`
  window.history.replaceState({}, '', url)
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)

Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key])
})

app.use(store)
app.use(pinia)
app.use(i18n)
app.use(initRouter())

Tracker.initDatadog()
initApi()

store.commit('signupContext', generateSignupContext())

const appSpinner = document.querySelector('#app-spinner')
if (appSpinner != null) {
  appSpinner.parentNode.removeChild(appSpinner)
}
app.mount('#app')
// tracking is enabled by default
;(function checkTrackingAcceptance() {
  let trackingAccepted = null

  try {
    trackingAccepted = new Event('trackingAccepted')
  } catch {
    trackingAccepted = document.createEvent('Event')
    trackingAccepted.initEvent('trackingAccepted', false, false)
  }
  document.dispatchEvent(trackingAccepted)
})()

export default app
