<template>
  <div class="corp-header">
    <div
      v-if="corporate?.logoFile"
      class="corp-logo"
    >
      <img
        :src="corporate.logoFile"
        alt="logo"
      />
    </div>
    <div
      v-else-if="corporate?.title"
      class="corp-logo-text"
    >
      {{ corporate.title }}
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const props = defineProps({
  corporate: {
    type: Object,
    default: null,
  },
})

const corporate = computed(() => props.constructor || store.getters.corporate)
</script>

<style lang="scss" scoped>
.corp-header {
  .corp-logo {
    img {
      max-height: 80px;
      max-width: 320px;

      @media (max-width: $breakpoint-mobile) {
        max-height: 80px;
        max-width: 272px;
      }
    }
  }

  .corp-logo-text {
    font-weight: $font-weight-bold;
    font-size: 40px;
  }
}
</style>
