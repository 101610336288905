import { createRouter, createWebHistory } from 'vue-router'
import Tracker from '@shared/Tracker'
import store from '@us/app/state/store'
import { fromAppToStorage, isFromApp } from '@shared/helpers/isFromApp'
import VueCookieConsent from '@shared/VueCookieConsent'
import { getCookie } from '@shared/utils.js'
import env from '@shared/env.js'
import trackDetailedViewScreen from '@us/app/helpers/trackDetailedViewScreen.js'
import useAuthStore from '@shared/store/useAuthStore.js'
import appModuleRouter from '@us/app/router/appModule'
import assessmentModuleRouter from '@shared/modules/assessment/router'

const initRouter = () => {
  const authStore = useAuthStore()

  const routerModules = [appModuleRouter, assessmentModuleRouter]

  const router = createRouter({
    routes: [
      // aggregate all routes from all modules
      ...routerModules.flatMap((module) => module.routes),
      // define a catch-all route
      {
        path: '/:pathMatch(.*)*',
        redirect: {
          name: appModuleRouter.ROUTE.B2B_PARTNERS,
          params: {},
        },
      },
    ],
    linkActiveClass: 'open active',
    scrollBehavior: (to, from, savedPosition) =>
      savedPosition || { x: 0, y: 0 },
    history: createWebHistory(env.VITE_PUBLIC_PATH),
  })

  router.beforeEach(async (routeTo, routeFrom) => {
    if (routeTo.query.from_app) {
      fromAppToStorage()

      const { client_app_id, client_version } = routeTo.query

      authStore.hostClientAppId = Array.isArray(client_app_id)
        ? client_app_id[0]
        : client_app_id
      authStore.hostClientVersion = Array.isArray(client_version)
        ? client_version[0]
        : client_version
    }

    VueCookieConsent(
      window,
      document,
      getCookie('CookieConsent'),
      store.getters.country,
      isFromApp(),
      store.getters.lang,
    )

    for (const module of routerModules) {
      // if the route is part of the module, run the hooks
      if (module.routes.find((route) => route.name === routeTo.name)) {
        return module.hooks(routeTo, routeFrom)
      }
    }
  })

  router.afterEach((routeTo) => {
    Tracker.trackPageView(routeTo.fullPath)

    if (routeTo.meta && routeTo.meta.name) {
      // detailedTrackViewScreen
      const detailedViewScreenRouteNames = [
        'onb_eligibility_verification',
        'onb_account_creation',
        'corporate_landing_page',
        'simplified_corporate_landing_page',
      ]
      if (detailedViewScreenRouteNames.includes(routeTo.meta.name)) {
        trackDetailedViewScreen(
          Tracker,
          routeTo.meta.name,
          store.getters['corporate'],
          store.getters['utmData'],
        )
      } else {
        Tracker.trackViewScreen(routeTo.meta.name)
      }
    }
  })

  // ensure that the first page_view that is ever tracked on a session contains the initial Href
  // (this is needed for proper utm tracking)
  Tracker.trackPageView(window.location.href)

  return router
}

export default initRouter
