<template>
  <div>
    <!-- we need to cache images in advance due to performance reasons -->
    <AssessmentSelfTestAssetsCache_MSK_US />

    <FullPagePreloader v-if="showPreloader" />

    <div
      v-else-if="env.isEu"
      class="content"
    >
      <BaseBanner
        variant="warning"
        :title="$t('generic__error_title')"
        :description="$t('generic__error__try_again_in_the_app')"
      />
    </div>

    <form
      v-else
      class="kds-main-wrapper content"
    >
      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('b2c_log_in')"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="
            $t('assessment_login_description_with_support_email', {
              VUE_APP_SUPPORT_EMAIL: env.VITE_SUPPORT_EMAIL,
            })
          "
        />
      </div>
      <BaseBanner
        v-if="message != null"
        :variant="message.type"
        :description="$t(message.text)"
      />
      <p
        class="kds-typography-display-small"
        v-html="$t('b2b_login_account_block_title')"
      />
      <div class="kds-content-block">
        <TextField
          v-bind="fieldAttrs.email"
          type="email"
          autocomplete="email"
          :label="$t('generic_field_email')"
          data-qa="email"
        />
        <PasswordField
          v-bind="fieldAttrs.password"
          data-qa="password"
          autocomplete="current-password"
        />
      </div>
      <BaseButton
        v-bind="submitAttrs"
        data-qa="submit"
        form="login_form"
        :text="$t('generic_login')"
      />
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { logE } from '@shared/utils'
import { sendBrazeEvent } from '@shared/api-endpoints'
import FullPagePreloader from '@shared/components/FullPagePreloader.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import AssessmentSelfTestAssetsCache_MSK_US from '@shared/components/AssessmentSelfTestAssetsCache_MSK_US.vue'
import { useRoute, useRouter } from 'vue-router'
import env from '@shared/env.js'
import useAuthStore from '@shared/store/useAuthStore.js'
import useForm from '@shared/composables/useForm.js'
import TextField from '@shared/components/form/TextField.vue'
import PasswordField from '@shared/components/form/PasswordField.vue'

const store = useStore()
const authStore = useAuthStore()
const route = useRoute()
const router = useRouter()

const showPreloader = ref(true)
const message = ref(null)
const formData = computed(() => store.getters['formData'])

const loginWithOneTimeAuthToken = async (token) =>
  store.dispatch('loginWithOneTimeAuthToken', {
    token,
  })

const tryLoginWithOneTimeAuthToken = async () => {
  try {
    return (
      route.query.one_time_token &&
      (await loginWithOneTimeAuthToken(route.query.one_time_token)) &&
      authStore.isAuthenticated
    )
  } catch (e) {
    logE(e)
    return false
  }
}

const startAssessment = async () => {
  await store.dispatch('assessment/getQuestionnaireConfig')
  sendBrazeEvent({
    name: 'milestone_assessment_started',
  })
  await store.dispatch('assessment/openCurrentQuestionnaire', {
    router: router,
  })
}

const form = ref({
  email: formData.value.email || route.query.email || '',
  password: formData.value.password || '',
})

const { fieldAttrs, submitAttrs } = useForm(
  form,
  ref({
    email: { validations: ['required', 'email'] },
    password: { validations: ['required'] },
  }),
  {
    onSubmit: async () => {
      const stayLoggedIn = env.isProd || window.stayLoggedIn !== false
      return store.dispatch('login', {
        email: form.value.email,
        password: form.value.password,
        stayLoggedIn,
      })
    },
    onSuccess: startAssessment,
    onError: (error) => {
      message.value = null
      if (!error || !error.response) {
        message.value = {
          text: 'b2c_common_error_unknown',
          type: 'warning',
        }
        return
      }
      switch (error.response.status) {
        case 403:
          message.value = {
            text: 'b2c_common_error_signin_forbidden',
            type: 'warning',
          }
          break
        case 401:
          message.value = {
            text: 'b2c_common_error_unauthorized',
            type: 'warning',
          }
          break
        default:
          message.value = {
            text: 'b2c_common_error_unknown',
            type: 'warning',
          }
      }
      message.value.show = true
    },
  },
)

onMounted(async () => {
  if (authStore.isAuthenticated || (await tryLoginWithOneTimeAuthToken())) {
    return await startAssessment()
  }
  showPreloader.value = false
})
</script>

<style lang="scss" scoped>
.content {
  padding-top: 24px;
}
</style>
