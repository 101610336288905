<template>
  <div class="corporate-eligibility-verification-media">
    <AppLogo />
    <div class="information-wrapper">
      <h2
        class="information-title"
        v-html="$t('b2b_info_title')"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'B2BCorporateEligibilityVerificationMedia',
  components: { AppLogo },
}
</script>

<style lang="scss">
.corporate-eligibility-verification-media {
  background-color: $sky;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 60px 0 60px;
  justify-content: flex-start;
  min-height: 100vh;

  background-image: url('@shared/assets/img/b2b/corporate-eligibility-verification-media.png');
  background-position: center 300px;
  background-repeat: no-repeat;
  background-size: contain;

  .app-logo {
    padding: 0 60px;
  }

  .information-wrapper {
    overflow: hidden;
    padding-bottom: 100px;
  }

  .information-title {
    font-weight: $font-weight-bold;

    max-width: 480px;
    padding-left: 60px;
  }
}
</style>
