import us_msk_ios_en from '@us/app/assets/img/qr-ios-en-prod-msk-us.svg'
import us_msk_ios_de from '@us/app/assets/img/qr-ios-de-prod-msk-us.svg'
import us_msk_android_en from '@us/app/assets/img/qr-android-en-prod-msk-us.svg'
import us_msk_android_de from '@us/app/assets/img/qr-android-de-prod-msk-us.svg'

import eu_msk_ios_en from '@eu/app/assets/img/qr-ios-en-prod-msk-eu.svg'
import eu_msk_ios_de from '@eu/app/assets/img/qr-ios-de-prod-msk-eu.svg'
import eu_msk_android_en from '@eu/app/assets/img/qr-android-en-prod-msk-eu.svg'
import eu_msk_android_de from '@eu/app/assets/img/qr-android-de-prod-msk-eu.svg'

import eu_copd_ios_en from '@eu/app/assets/img/qr-ios-en-prod-copd-eu.svg'
import eu_copd_ios_de from '@eu/app/assets/img/qr-ios-de-prod-copd-eu.svg'
import eu_copd_android_en from '@eu/app/assets/img/qr-android-en-prod-copd-eu.svg'
import eu_copd_android_de from '@eu/app/assets/img/qr-android-de-prod-copd-eu.svg'

// TODO PUT Correct QR codes once in place from native apps.
import us_copd_ios_en from '@eu/app/assets/img/qr-ios-en-prod-copd-eu.svg'
import us_copd_ios_de from '@eu/app/assets/img/qr-ios-de-prod-copd-eu.svg'
import us_copd_android_en from '@eu/app/assets/img/qr-android-en-prod-copd-eu.svg'
import us_copd_android_de from '@eu/app/assets/img/qr-android-de-prod-copd-eu.svg'
import env from '@shared/env.js'

const urls = {
  us_msk_ios_en,
  us_msk_ios_de,
  us_msk_android_en,
  us_msk_android_de,

  us_copd_ios_en,
  us_copd_ios_de,
  us_copd_android_en,
  us_copd_android_de,

  eu_msk_ios_en,
  eu_msk_ios_de,
  eu_msk_android_en,
  eu_msk_android_de,

  eu_copd_ios_en,
  eu_copd_ios_de,
  eu_copd_android_en,
  eu_copd_android_de,
}

export default function getQRCodeUrl(lang, platform) {
  platform = platform === 'ios' ? platform : 'android'

  const therapy = env.VITE_DISEASE
  const region = env.VITE_GEO

  lang = typeof lang === 'string' && lang.startsWith('de') ? 'de' : 'en'

  return urls[`${region}_${therapy}_${platform}_${lang}`]
}
