<template>
  <div class="quiz-content__inner welcome-theme self-test">
    <div class="self-test__layout">
      <h3
        class="self-test__header"
        v-html="$t('b2b_self_test_get_started')"
      />
      <div
        class="self-test__description"
        v-html="$t('b2b_self_test_get_started_content')"
      />

      <div class="welcome-theme__figure">
        <LottieAnimationContainer :json-config-path="jsonConfigPath" />
      </div>

      <div class="interactive-button__layout">
        <BaseButton
          :text="$t('b2b_get_started_btn_text')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>

      <div class="welcome-info">
        <img
          src="@shared/assets/img/b2b/icon-info-midnight.svg"
          alt="info icon"
          class="welcome-info__icon"
          @click="showInfoPopup = true"
        />
        <span
          class="welcome-info__hint"
          v-html="$t('b2b_self_test_welcome_questions_info_more')"
        />
      </div>
    </div>

    <QuizInfoPopup
      v-if="showInfoPopup"
      :text="$t('b2b_self_test_welcome_questions_info_hint')"
      :top-position="infoPopupTopPosition"
      title=""
      @close-popup="showInfoPopup = false"
    />
  </div>
</template>

<script>
import BaseButton from '@shared/components/BaseButton.vue'
import QuizInfoPopup from '@shared/components/Quiz/QuizInfoPopup.vue'
import LottieAnimationContainer from '@shared/components/LottieAnimationContainer.vue'
import env from '@shared/env.js'

export default {
  name: 'QuestionnaireSelfTestWelcome',
  components: {
    BaseButton,
    LottieAnimationContainer,
    QuizInfoPopup,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showInfoPopup: false,
    }
  },
  computed: {
    jsonConfigPath() {
      return `${env.VITE_PUBLIC_PATH}static/lottie-self-test-welcome-media.json`
    },
    infoPopupTopPosition() {
      return document.documentElement.scrollTop
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';
.welcome-theme {
  background-color: $light-blue;

  &__figure {
    display: none;
    margin: 0 auto;
    width: 66%;
  }

  .quiz-nav-progress {
    display: none;
  }

  .self-test__header {
    margin: 16px 0 8px;

    @include kaia-typography-h5($font-weight-bold);
  }

  .self-test__description {
    color: $midnight;
    margin-bottom: 21px;

    @include kaia-typography-p2($font-weight-normal);
  }

  .welcome-info {
    margin: 35px 0 0;
    text-align: center;

    &__icon,
    &__hint {
      vertical-align: middle;
    }

    &__icon {
      cursor: pointer;
    }

    &__hint {
      color: $text-secondary;
      margin-left: 10px;

      @include kaia-typography-p1($font-weight-normal);
    }
  }

  .interactive-button__layout {
    margin: 35px auto 0;
  }

  @media (max-width: $breakpoint-tablet) {
    background-color: $sky;

    &__figure {
      display: block;
    }
  }
}
</style>
