import corporateTrackingMap from '@us/app/config/corporate_tracking_map'

const getCorporateTrackingRecord = (alias) => {
  alias = typeof alias === 'string' ? alias : String(alias)

  const record = corporateTrackingMap[alias.toLowerCase()] || {}
  const query = record.query || {}

  return {
    alias,
    corporate: record.corporate || alias,
    record: {
      utm_source: query.utm_source || '',
      utm_medium: query.utm_medium || '',
      utm_campaign: query.utm_campaign || '',
      utm_content: query.utm_content || '',
      utm_term: query.utm_term || '',
    },
  }
}

const getCorporateTrackingSearchParams = (urlQueryString, corporateKey) => {
  const searchParams = new URLSearchParams(urlQueryString)
  corporateKey = searchParams.get(corporateKey)

  if (corporateKey == null) {
    return null
  }

  const nextQueryParams = {}
  const corporateTrackingRecord = getCorporateTrackingRecord(corporateKey)

  if (corporateTrackingRecord.corporate === corporateKey) {
    searchParams.forEach((value, key) => {
      nextQueryParams[key] = encodeURIComponent(value)
    })
  } else {
    searchParams.forEach((value, key) => {
      if (!(key in corporateTrackingRecord.record)) {
        nextQueryParams[key] = encodeURIComponent(value)
      }
    })

    for (const i in corporateTrackingRecord.record) {
      if (corporateTrackingRecord.record[i]) {
        nextQueryParams[i] = encodeURIComponent(
          corporateTrackingRecord.record[i],
        )
      }
    }
  }
  nextQueryParams.corporate = encodeURIComponent(
    corporateTrackingRecord.corporate,
  )

  return nextQueryParams
}

export default getCorporateTrackingSearchParams
