<template>
  <div class="simple-landing-layout">
    <SimpleStartJourney
      v-if="showSimpleStartJourney"
      :cta-btn-title="ctaBtnTitle"
      :corporate="corporate"
      :handle-c-t-a-button-click="handleSimpleStartJourneyCTAClick"
      :is-german="isGerman"
    />
    <SimpleCheckIn
      v-if="showSimpleCheckIn"
      :handle-c-t-a-button-click="handleSimpleCheckInCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
    <SimplePlanOutline
      v-if="showSimplePlanOutline"
      :handle-c-t-a-button-click="handleSimplePlanOutlineCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
    <SimpleTestimonial
      v-if="showSimpleTestimonial"
      :handle-c-t-a-button-click="handleSimpleTestimonialCTAClick"
      :corporate="corporate"
      :utms="utmData"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SimpleStartJourney from '@us/app/components/SimpleStartJourney.vue'
import SimpleCheckIn from '@us/app/components/SimpleCheckIn.vue'
import SimplePlanOutline from '@us/app/components/SimplePlanOutline.vue'
import SimpleTestimonial from '@us/app/components/SimpleTestimonial.vue'
import ROUTE from '@us/app/router/appModule/names'
import { useI18n } from 'petite-vue-i18n'

const pages = {
  SIMPLE_START_JOURNEY: 1,
  SIMPLE_CHECK_IN: 2,
  SIMPLE_PLAN_OUTLINE: 3,
  SIMPLE_TESTIMONIAL: 4,
}

export default {
  name: 'B2BSimpleLandingLayout',
  components: {
    SimpleStartJourney,
    SimpleCheckIn,
    SimplePlanOutline,
    SimpleTestimonial,
  },
  props: {
    setPageTitle: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      currentPage: pages.SIMPLE_START_JOURNEY,
    }
  },
  computed: {
    ...mapGetters(['lang', 'corporate', 'utmData']),
    showSimpleStartJourney() {
      return this.currentPage === pages.SIMPLE_START_JOURNEY
    },
    showSimpleCheckIn() {
      return this.currentPage === pages.SIMPLE_CHECK_IN
    },
    showSimplePlanOutline() {
      return this.currentPage === pages.SIMPLE_PLAN_OUTLINE
    },
    showSimpleTestimonial() {
      return this.currentPage === pages.SIMPLE_TESTIMONIAL
    },
    isGerman() {
      return this.lang.indexOf('de-') === 0 || this.lang === 'de'
    },
    ctaBtnTitle() {
      return this.t('b2b__landing_page__generic__cta_btn__title')
    },
    pageTitle() {
      return this.t('b2b__landing_page__generic__page_title')
    },
  },
  watch: {
    $route(to) {
      this.updateCurrentPage(to)
      requestAnimationFrame(() => {
        document.documentElement.scrollTop = 0
      })
    },
  },
  created() {
    this.utmsToStore(window.location.search)
    this.updateCurrentPage(this.$route)
    this.updatePageTitle()

    requestAnimationFrame(() => {
      if (document.documentElement.scrollTop > 0) {
        document.documentElement.scrollTop = 0
      } else if (document.body.scrollTop > 0) {
        document.body.scrollTop = 0
      }
    })
  },
  updated() {
    this.updatePageTitle()
  },
  methods: {
    ...mapActions(['utmsToStore']),
    handleSimpleStartJourneyCTAClick() {
      setTimeout(() => {
        this.$router.push({
          name: ROUTE.B2B_SIMPLE_CHECK_IN,
        })
      }, 400)
    },
    handleSimpleCheckInCTAClick(choice) {
      this.$store.commit('checkInStatus', choice)

      setTimeout(() => {
        this.$router.push({
          name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE,
        })
      }, 400)
    },
    handleSimplePlanOutlineCTAClick() {
      setTimeout(() => {
        this.$router.push({
          name: ROUTE.B2B_SIMPLE_TESTIMONIAL,
        })
      }, 400)
    },
    handleSimpleTestimonialCTAClick() {
      this.goToNext()
    },
    goToNext() {
      if (this.corporate) {
        this.$router.push({
          name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
        })
      } else {
        this.$router.push({
          name: ROUTE.B2B_PARTNERS,
        })
      }
    },
    updatePageTitle() {
      this.setPageTitle(
        [
          this.corporate?.title ? `${this.corporate.title} | ` : '',
          this.pageTitle,
        ].join(''),
      )
    },
    updateCurrentPage(to) {
      ;(to.name === ROUTE.B2B_SIMPLE_PLAN_OUTLINE ||
        to.name === ROUTE.B2B_SIMPLE_TESTIMONIAL) &&
        !this.$store.getters.checkInStatus &&
        this.$router.push({
          name: ROUTE.B2B_SIMPLE_CHECK_IN,
        })

      if (to.name === ROUTE.B2B_INFORMATION) {
        this.currentPage = pages.SIMPLE_START_JOURNEY
      } else if (to.name === ROUTE.B2B_SIMPLE_CHECK_IN) {
        this.currentPage = pages.SIMPLE_CHECK_IN
      } else if (to.name === ROUTE.B2B_SIMPLE_PLAN_OUTLINE) {
        this.currentPage = pages.SIMPLE_PLAN_OUTLINE
      } else if (to.name === ROUTE.B2B_SIMPLE_TESTIMONIAL) {
        this.currentPage = pages.SIMPLE_TESTIMONIAL
      }
    },
  },
}
</script>

<style lang="scss">
html,
body {
  height: 100% !important;
}

.app {
  display: block !important;
  height: 100% !important;
}

.simple-landing-layout {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  margin: 0 auto;
  background-color: $blue-strong;

  @media (min-width: $size-large) {
    max-width: 100%;
  }
}
</style>
