<template>
  <div class="quiz-content__inner self-test worst-pain-location">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_pain_location_header')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_worst_pain_location_subtitle')"
        />
      </div>
      <div class="wrap-mobile">
        <div class="person-wrap mobile">
          <PainLocations
            :css-classes="['images', 'non-interactive', 'transparent']"
            :body-parts="[worstPainLocation]"
          />
        </div>
        <fieldset class="form-container">
          <div
            v-for="painLocation in painLocationListParts"
            :key="painLocation.key"
            :class="[
              'quiz-radio',
              { 'quiz-radio--checked': painLocationSelected(painLocation) },
            ]"
            @click="handlePainLocationChange(painLocation)"
          >
            <label class="quiz-radio__label kaia-p2-semi-bold">
              {{ $t(painLocation.value) }}
            </label>
            <div class="quiz-radio__layout">
              <CustomRadio
                :value="painLocation.key"
                :checked="painLocationSelected(painLocation)"
                :data-q-a-value="painLocation.key"
                data-q-a="pain-location"
              />
            </div>
          </div>
        </fieldset>
      </div>

      <div class="interactive-button__layout">
        <BaseButton
          :disabled="!isValid"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useI18n } from 'petite-vue-i18n'

import painLocations from '@shared/modules/assessment/config/pain-locations.json'

import Tracker from '@shared/Tracker'
import CustomRadio from '@shared/components/CustomRadio.vue'
import PainLocations from '@shared/components/PainLocations.vue'
import BaseButton from '@shared/components/BaseButton.vue'

export default {
  name: 'SelfTestWorstPainLocation',
  components: {
    CustomRadio,
    BaseButton,
    PainLocations,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      painLocationListParts: painLocations,
    }
  },
  computed: {
    ...mapGetters('assessment', ['worstPainLocation']),
    isValid() {
      return this.worstPainLocation.length > 0
    },
  },
  methods: {
    handlePainLocationChange(painLocation) {
      Tracker.trackLinkClick({
        targetUrl: this.$route.path,
        elementId: 'worst-pain-location-selected',
        elementClasses: [],
        elementTarget: '',
        elementContent: painLocation.key,
      })

      this.$store.commit('assessment/updateWorstPainLocation', painLocation.key)
    },
    painLocationSelected(painLocation) {
      return this.worstPainLocation === painLocation.key
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';

.worst-pain-location {
  .interactive-button__layout {
    margin: 32px auto 0;
  }

  .form-container {
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      flex: 0 0 50%;
    }

    ul {
      list-style: none;
      padding: 0;
      max-width: 420px;
      margin: 0 auto;
    }

    li {
      margin-bottom: 8px;
    }
  }

  .wrap-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .mobile {
      display: none;

      @media (max-width: $breakpoint-tablet) {
        display: flex;
      }

      @media (max-width: $breakpoint-mobile-360) {
        margin-right: 0;
        width: 50%;
        box-sizing: border-box;
      }
    }
  }
}
</style>
