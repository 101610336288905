<template>
  <div class="quiz-content__inner slider-view self-test">
    <div class="self-test__layout">
      <div class="form-container">
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_pain_intensity_question')"
        />
      </div>
      <div class="slider-view__slider">
        <ScoreSlider
          :max-score="10"
          :score="getScoreSliderValue"
          :label="getScoreSliderLabel"
          data-q-a="score-slider"
          @change="handlePainIntensityChange"
          @tap="handlePainIntensityChange"
        />
        <div class="slider-view__hints">
          <div
            class="slider-view__hint"
            v-html="$t('b2b_self_test_pain_intensity_no_pain')"
          />
          <div
            class="slider-view__hint"
            v-html="$t('b2b_self_test_pain_intensity_severe_pain')"
          />
        </div>
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useI18n } from 'petite-vue-i18n'

import BaseButton from '@shared/components/BaseButton.vue'
import ScoreSlider from '@shared/components/ScoreSlider.vue'

export default {
  name: 'QuestionnaireSelfTestPainIntensity',
  components: {
    ScoreSlider,
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', ['painIntensity']),
    painIntensityIsValid() {
      return this.painIntensity >= 0 && this.painIntensity <= 10
    },
    getScoreSliderValue() {
      return this.painIntensity === -1 ? 5 : this.painIntensity
    },
    getScoreSliderLabel() {
      return this.painIntensity === -1 ? '' : `${this.painIntensity}`
    },
  },
  created() {
    this.interactiveButtonState = this.painIntensityIsValid
      ? 'ACTIVE'
      : 'DISABLED'
  },
  methods: {
    handlePainIntensityChange({ value }) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updatePainIntensity', value)
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';
@import '../css/SelfTestSliderView';
</style>
