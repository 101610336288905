<template>
  <div class="welcome-to-kaia self-test precautions">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b__red_flags__sub_title')"
        />
      </div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="red-flag"
      >
        <div class="red-flag-question">
          <div
            class="red-flag-question__title"
            v-html="item.question"
          />
          <DropdownArrowControl
            :default-state="item.checked"
            :css-classes="['dropdown-arrow-control-red-flag']"
            :disabled="formIsDisabled"
            @click="
              (nextState) => {
                switchMedicalConditionControl(nextState, item)
              }
            "
          />
        </div>
        <div :class="getRedFlagAnswerCSSClasses(item)">
          <div
            class="red-flag-description"
            :aria-hidden="!item.checked"
            v-html="item.answer"
          />
        </div>
      </div>

      <ConfirmationBox
        class="info-box-layout"
        :label-title="$t('b2b__red_flags__checkbox_label')"
        :cta-title="$t('b2b_sign_up_submit_button_text')"
        :checked="redFlagApproved"
        :cta-enabled="isFormValid && !formIsDisabled"
        @change="handleConfirmationBoxChange"
        @submit="next"
      />
    </div>
  </div>
</template>

<script>
import ConfirmationBox from '@shared/components/ConfirmationBox.vue'
import DropdownArrowControl from '@shared/components/DropdownArrowControl.vue'
import { sendBrazeEvent } from '@shared/api-endpoints'

import precautions from '@shared/modules/assessment/config/precautions-msk-eu.json'
import { useI18n } from 'petite-vue-i18n'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireSelfTestPrecautionsMSKEU',
  components: {
    DropdownArrowControl,
    ConfirmationBox,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      items: precautions.map((f) => ({
        question: this.t(f.question),
        answer: this.t(f.answer),
      })),
      formIsDisabled: false,
    }
  },
  computed: {
    ...mapGetters({
      redFlagApproved: 'assessment/redFlagApproved',
    }),
    isFormValid() {
      return this.redFlagApproved
    },
  },
  methods: {
    handleConfirmationBoxChange({ checked }) {
      this.$store.commit('assessment/updateRedFlagApproved', checked)
    },
    async next() {
      if (this.formIsDisabled) {
        return
      }
      this.formIsDisabled = true

      sendBrazeEvent({
        name: 'milestone_red_flags_approved',
      })

      this.handleNextEvent()
    },
    getRedFlagAnswerCSSClasses(item) {
      return ['red-flag-answer', !item.checked ? 'red-flag-answer--hidden' : '']
    },
    switchMedicalConditionControl(nextState, item) {
      item.checked = nextState
    },
  },
}
</script>

<style lang="scss">
@import '../css/SelfTest';

.precautions {
  .self-test {
    &__layout {
      max-width: 600px;
    }
  }
}

.dropdown-arrow-control-red-flag {
  width: 24px;
  height: 20px;
}

.red-flag {
  border-bottom: 1px solid $gray-base;
  overflow: hidden;
  min-height: 56px;
}

.red-flag-question {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  background-color: $white;

  padding: 18px 0;
  margin-right: 6px;

  &__title {
    flex-basis: 80%;

    @include kaia-typography-p1($font-weight-semi-bold);
  }
}

.red-flag-answer {
  opacity: 1;
  transition: opacity 0.75s linear;

  &--hidden {
    opacity: 0;
    height: 0;
  }
}

.red-flag-description {
  color: $text-secondary;
  padding-bottom: 32px;

  @include kaia-typography-p1($font-weight-normal);
}

.welcome-to-kaia {
  background: $white;
  max-height: 100%;
  overflow-y: auto;
  height: 100%;

  .quiz-content-container {
    background: none !important;
  }

  .content-header__info-button {
    background: url('@shared/assets/img/b2b/ico-info.svg') no-repeat;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    cursor: pointer;
    min-width: 22px;

    &--hidden {
      pointer-events: none;
      opacity: 0;
    }
  }

  .content-sub-header {
    margin: 50px 0 24px;

    &__title {
      @include kaia-typography-p3($font-weight-normal);

      margin: 0 0 44px;
    }

    &__description {
      @include kaia-typography-p1($font-weight-normal);
    }
  }

  .confirmation-container {
    :deep(.quiz-content-container__inner) {
      margin: 0 auto;
      width: calc(100% - 80px);
      padding: 0 40px;
      @media (max-width: $breakpoint-mobile) {
        padding: 0 20px;
        width: calc(100% - 40px);
      }
    }
  }
}

.info-box-layout {
  margin-top: 32px;
}

.next-button-layout {
  .btn {
    width: 250px;
    height: 58px;
    border-radius: 50px;
    background: $midnight;

    &::after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      background-image: url(@shared/assets/img/short_right_arrow.svg);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-disabled {
      background-color: $gray-5;
      border-color: $gray-5;
      color: $gray-35;

      &::after {
        background-position: left -24px;
      }
    }
  }
}
</style>
