import AbstractModel from './utils/abstractModel'
import types from './utils/types'

export default class UserProfile extends AbstractModel {
  /**
   * @type {Date, null} // 2023-07-19T16:22:21.074Z
   */
  acceptedDataProtectionAt

  /**
   * @type {Date, null} // 2023-07-19T16:22:21.074Z
   */
  acceptedTrackingAt

  /**
   * @type {number, null}
   */
  corporateId

  /**
   * @type {string}
   */
  language

  constructor(json) {
    super(json)
    this.acceptedDataProtectionAt = this.parseDate(
      json.accepted_data_protection_at,
    )
    this.acceptedTrackingAt = this.parseDate(json.accepted_tracking_at)
    this.corporateId = json.corporate_id
    this.language = json.language

    this.validate()
  }

  validate() {
    this.assertType('acceptedDataProtectionAt', [types.DATE, types.NULL])
    this.assertType('acceptedTrackingAt', [types.DATE, types.NULL])
    this.assertType('corporateId', [types.NUMBER, types.NULL])
    this.assertType('language', [types.STRING])
  }

  /**
   * Identifies whether user accepted DPA
   * @readonly
   * @type {boolean}
   */
  get hasAcceptedDataProtection() {
    return this?.acceptedDataProtectionAt !== null
  }

  /**
   * Identifies whether user gave tracking consent
   *
   * @readonly
   * @type {boolean}
   */
  get hasAcceptedTracking() {
    return this?.acceptedTrackingAt !== null
  }
}
