import enMSK from './en-msk.json'
import deMSK from './de-msk.json'
import esMSK from './es_US-msk.json'

import enCOPD from './en-copd.json'
import deCOPD from './de-copd.json'
import env from '@shared/env.js'

const localisation = {}

// TODO: should be split in the future;
// Refine translations;
switch (env.VITE_DISEASE) {
  case 'copd':
    localisation.de = Object.assign(deMSK, deCOPD)
    localisation.en = Object.assign(enMSK, enCOPD)
    localisation.es = esMSK
    break

  default:
    localisation.de = deMSK
    localisation.en = enMSK
    localisation.es = esMSK
}

export default localisation
