<template>
  <div class="simple-testimonial">
    <div
      ref="body"
      class="b__body b__transition b__transition--enter-from"
    >
      <img
        :src="imageSrc"
        class="b__image"
        alt="Testimonial Image"
      />

      <span class="b__description">{{ $t(descriptionText) }}</span>

      <div class="b__author">&mdash; {{ $t(authorText) }}</div>

      <BaseButton
        :text="$t('generic_action_continue')"
        @click="handleCTAButtonClick"
      />
    </div>
  </div>
</template>

<script>
import notOftenImg from '@us/app/assets/img/testimonial-not-often.png'
import onceInAWhileImg from '@us/app/assets/img/testimonial-once-in-a-while.png'
import aFewTimesAWeekImg from '@us/app/assets/img/testimonial-a-few-times-a-week.png'
import dailyBattleImg from '@us/app/assets/img/testimonial-daily-battle.png'
import trackDetailedViewScreen from '@us/app/helpers/trackDetailedViewScreen.js'

import BaseButton from '@shared/components/BaseButton.vue'
import Tracker from '@shared/Tracker'

export default {
  name: 'SimpleTestimonial',
  components: {
    BaseButton,
  },
  props: {
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    corporate: {
      type: Object,
      default: () => {},
    },
    utms: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    checkInStatus() {
      return this.$store.getters.checkInStatus
    },
    imageSrc() {
      switch (this.checkInStatus) {
        case 'not_often':
          return notOftenImg

        case 'once_in_a_while':
          return onceInAWhileImg

        case 'a_few_times_a_week':
          return aFewTimesAWeekImg

        default:
          return dailyBattleImg
      }
    },
    descriptionText() {
      switch (this.checkInStatus) {
        case 'not_often':
          return 'b2b__simple_landing_page__testimonial__description_not_often'

        case 'once_in_a_while':
          return 'b2b__simple_landing_page__testimonial__description_once_in_a_while'

        case 'a_few_times_a_week':
          return 'b2b__simple_landing_page__testimonial__description_a_few_times_a_week'

        default:
          return 'b2b__simple_landing_page__testimonial__description_daily_battle'
      }
    },
    authorText() {
      switch (this.checkInStatus) {
        case 'not_often':
          return 'b2b__simple_landing_page__testimonial__author_not_often'

        case 'once_in_a_while':
          return 'b2b__simple_landing_page__testimonial__author_once_in_a_while'

        case 'a_few_times_a_week':
          return 'b2b__simple_landing_page__testimonial__author_a_few_times_a_week'

        default:
          return 'b2b__simple_landing_page__testimonial__author_daily_battle'
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.$refs.body?.classList.remove('b__transition--enter-from')

      trackDetailedViewScreen(
        Tracker,
        'onb_testimonial',
        this.corporate,
        this.utms,
      )
    }, 100)
  },
}
</script>

<style lang="scss">
@import '@us/app/components/css/SimpleTestimonial.scss';
</style>
