<template>
  <div class="in-network-with">
    <div class="in-network-with__wrapper--content">
      <div class="in-network-with__wrapper--content--corporate">
        <p class="kds-typography-title-large">
          {{ $t('uhc_vpt_primer__in_network__title') }}
        </p>
        <img
          src="@us/app/assets/img/unitedhealthcare-narrow.svg"
          alt="UHC Logo"
        />
      </div>
      <p class="kds-typography-paragraph-medium">
        {{ $t('uhc_vpt_primer__in_network__description') }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.in-network-with {
  background: $kds-color-text-on-blue;
  color: $kds-color-text-interactive-inverse;
  @include flex-container(row, center);

  &__wrapper {
    text-align: center;

    &--content {
      @include padding(lg);
      text-align: center;
      max-width: $size-large;

      &--corporate {
        @include flex-container(column, center, md);
        @include margin(lg, bottom);

        @include media-up(md) {
          @include flex-container(row, center, md, center);
        }

        img {
          height: 47px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(0%) hue-rotate(345deg) brightness(99%) contrast(102%);
        }
      }
    }
  }
}
</style>
