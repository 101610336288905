<template>
  <div
    v-if="env !== 'prod'"
    :class="['env-badge', env]"
  >
    <span class="kaia-h6-bold">
      {{ env.toUpperCase() }}
    </span>
  </div>
</template>

<script>
import env from '@shared/env.js'

export default {
  name: 'EnvBadge',
  computed: {
    env: () => env.VITE_MODE || 'dev',
  },
}
</script>

<style lang="scss" scoped>
.env-badge {
  background-color: $orange;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.15);
  height: 150px;
  left: -75px;
  pointer-events: none;
  position: absolute;
  top: -75px;
  transform: rotate(-45deg) scale(0.3);
  width: 150px;
  z-index: 9;

  &.stage {
    background-color: $green-highlight;
  }

  span {
    bottom: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}
</style>
