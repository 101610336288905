// define defaults (also useful for autocomplete)

const env = {
  /** @type {string|null} */
  VITE_API_BASE_URL: null,
  /** @type {string|null} */
  VITE_DATADOG_APP_ID: null,
  /** @type {string|null} */
  VITE_DATADOG_CLIENT_TOKEN: null,
  /** @type {string|null} */
  VITE_DATADOG_PROXY: null,
  /** @type {string|null} */
  VITE_DEEP_LINK: null,
  /** @type {string|null} */
  VITE_DEFAULT_COUNTRY_CODE: null,
  /** @type {string|null} */
  VITE_DEFAULT_TIMEZONE: null,
  /** @type {string|null} */
  VITE_DIGA_ENABLED: null,
  /** @type {string|null} */
  VITE_DISEASE: null,
  /** @type {string|null} */
  VITE_GEO: null,
  /** @type {string|null} */
  VITE_KAIA_APP_DEEP_LINK: null,
  /** @type {string|null} */
  VITE_LINK_APP_STORE_DE: null,
  /** @type {string|null} */
  VITE_LINK_APP_STORE_DEFAULT: null,
  /** @type {string|null} */
  VITE_LINK_APP_STORE_ES: null,
  /** @type {string|null} */
  VITE_LINK_GOOGLE_PLAY_DE: null,
  /** @type {string|null} */
  VITE_LINK_GOOGLE_PLAY_DEFAULT: null,
  /** @type {string|null} */
  VITE_LINK_GOOGLE_PLAY_ES: null,
  /** @type {string|null} */
  VITE_MODE: null,
  /** @type {string|null} */
  VITE_PUBLIC_PATH: null,
  /** @type {string|null} */
  VITE_SNOWPLOW_APP_ID: null,
  /** @type {string|null} */
  VITE_SNOWPLOW_COLLECTOR: null,
  /** @type {string|null} */
  VITE_SUBSCRIPTION_ACTIVATION_DEEP_LINKS_MIN_APP_VERSION: null,
  /** @type {string|null} */
  VITE_SUPPORTED_LANG_CODES: null,
  /** @type {string|null} */
  VITE_SUPPORT_EMAIL: null,
  /** @type {string|null} */
  VITE_VERSION: null,
}

// map all VITE_* env variables to env object
for (const [key, value] of Object.entries(import.meta.env)) {
  if (key.startsWith('VITE_')) {
    env[key] = value
  }
}

env.isProd = env.VITE_MODE === 'prod'

env.isCopd = env.VITE_DISEASE === 'copd'
env.isMsk = env.VITE_DISEASE === 'msk'

env.isUs = env.VITE_GEO === 'us'
env.isEu = env.VITE_GEO === 'eu'

env.isMskEu = env.isMsk && env.isEu
env.isMskUs = env.isMsk && env.isUs
env.isCopdEu = env.isCopd && env.isEu
env.isCopdUs = env.isCopd && env.isUs

env.features = {
  diga: env.VITE_DIGA_ENABLED === 'true',
}

export default env
