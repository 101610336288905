<template>
  <div class="simple-landing-footer">
    <div class="simple-landing-footer__label">
      {{ $t('b2b_static_contacts_name') }}
    </div>
    <div class="language-layout">
      <LanguageSwitcher />
    </div>

    <div class="simple-landing-footer__items">
      <div
        v-if="kaiaTermsEnabled"
        class="simple-landing-footer__items--item"
      >
        <DocumentViewer :link="staticTermsLink()" />
      </div>
      <div
        v-if="kaiaTermsEnabled"
        class="simple-landing-footer__items--item"
      >
        <DocumentViewer :link="staticPrivacyLink()" />
      </div>
      <div
        v-if="disclaimerText"
        class="simple-landing-footer__items--item"
      >
        <DocumentViewer>
          <template #default="{ viewDocument }">
            <a
              class="simple-landing-footer__link"
              href="#"
              @click="viewDocument"
            >
              {{ $t('b2b_static_disclaimer_link') }}
            </a>
          </template>
          <template #iframe>
            {{ disclaimerText }}
          </template>
        </DocumentViewer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'petite-vue-i18n'

import defineLanguage from '@shared/utils/defineLanguage'

import LanguageSwitcher from '@shared/components/LanguageSwitcher.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'
import env from '@shared/env.js'

const { t } = useI18n()
const props = defineProps({
  corporate: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
})

const kaiaTermsEnabled = computed(() => {
  return props.corporate?.kaiaTermsEnabled
})

const disclaimerText = computed(() => {
  return props.corporate?.disclaimerEnabled
    ? props.corporate.disclaimerText
    : null
})

const staticTermsLink = () =>
  aTagWrapper('b2b_static_terms_conditions_link', 'terms')

const staticPrivacyLink = () =>
  aTagWrapper('b2b_static_privacy_policy_link', 'privacy')

const aTagWrapper = (translation, type = '') => {
  const definedLang = defineLanguage(props.lang)
  const documentLang = definedLang === 'es' ? definedLang : 'us'
  const base = env.VITE_PUBLIC_PATH

  return `<a class="simple-landing-footer__link" href="#" data-link="${base}static/${documentLang}/${type}/index.html">${t(translation)}</a>`
}
</script>

<style lang="scss">
.simple-landing-footer {
  @include flex-container(column);

  .language-layout {
    margin-top: 8px;
    color: $text-secondary;
  }

  &__label {
    color: $text-secondary;
    text-align: start;

    @include kaia-typography-p2($font-weight-bold);
  }

  &__items {
    &--item {
      margin-top: 8px;
    }
  }

  &__link {
    color: $text-interactive-accent;
    text-decoration: none;

    @include kaia-typography-p1($font-weight-normal);
  }
}
</style>
