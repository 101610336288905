import { mapActions } from 'vuex'
import ROUTE from '@shared/modules/assessment/router/names'
import env from '@shared/env.js'

const NAV_TABLE = {
  msk: [
    [
      ROUTE.ASSESSMENT_SELF_TEST_WELCOME,
      ROUTE.ASSESSMENT_SELF_TEST_ABOUT,
      ROUTE.ASSESSMENT_SELF_TEST_WORST_PAIN_LOCATION,
      ROUTE.ASSESSMENT_SELF_TEST_PAIN_INTENSITY,
      ROUTE.ASSESSMENT_SELF_TEST_PAIN_DURATION,
      ROUTE.ASSESSMENT_SELF_TEST_ACTIVITIES,
      ROUTE.ASSESSMENT_SELF_TEST_FEAR_OF_MOVEMENT,
      ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_MSK,
      ROUTE.ASSESSMENT_SELF_TEST_COMMITMENT_INTRO,
    ],
  ],
  copd: [
    [
      ROUTE.ASSESSMENT_SELF_TEST_WELCOME,
      ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_COPD,
      ROUTE.ASSESSMENT_SELF_TEST_ABOUT,
      ROUTE.ASSESSMENT_SELF_TEST_PAIN_INTENSITY,
      ROUTE.ASSESSMENT_SELF_TEST_ACTIVITIES,
    ],
  ],
}

function getNavTable(disease) {
  const navTable = NAV_TABLE[disease]

  if (navTable === null || navTable === undefined) {
    throw new Error(`navTable for ${disease}`)
  }
  if (navTable.length === 0) {
    throw new Error(`navTable for ${disease}`)
  }
  if (navTable.some((stage) => stage.length === 0)) {
    throw new Error(`navTable for ${disease}`)
  }

  return navTable
}

function getRouteProperties(disease, currentRouteName, modifier) {
  const navTable = [].concat(...getNavTable(disease)) // flatten array of routes;
  const index = navTable.indexOf(currentRouteName) + modifier

  if (typeof index !== 'number' || index < 0 || index >= navTable.length) {
    throw new Error(
      `selfTestNavigation Mixin: Route cannot be determined because the target index is out of bounds. Current route name: ${currentRouteName}, modifier: ${modifier}, target index: ${index}`,
    )
  }

  const routeName = navTable[index]
  return { name: routeName }
}

export default {
  name: 'selfTestNavigation',
  computed: {
    stages() {
      return getNavTable(env.VITE_DISEASE)
    },
    stageProgress() {
      const stepsInStage = this.stages[this.stage].length
      const currentStepInStage = Math.max(
        0,
        this.stages[this.stage].findIndex((step) => step === this.$route.name),
      )
      return (currentStepInStage * 100) / stepsInStage
    },
    stageCount() {
      return this.stages.length
    },
    stage() {
      return Math.max(
        0,
        this.stages.findIndex((stage) => stage.includes(this.$route.name)),
      )
    },
  },
  methods: {
    ...mapActions('assessment', ['completeQuestionnaire']),
    async goToNextOrCompleteQuestionnaire() {
      try {
        let routeProperties = getRouteProperties(
          env.VITE_DISEASE,
          this.$route.name,
          +1,
        )

        this.$router.push(routeProperties)
      } catch {
        // complete questionnaires;
        await this.completeQuestionnaire({ router: this.$router })
      }
    },
    goToPreviousRoute() {
      let routeProperties = getRouteProperties(
        env.VITE_DISEASE,
        this.$route.name,
        -1,
      )

      this.$router.push(routeProperties)
    },
  },
}
